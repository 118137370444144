import React, { useState } from 'react'
import MarkdownMessage from '@gluedigital/markdown-message'

import token from 'src/static/images/banner-ido/token-ido.png'
import ape from 'src/static/logos/ido/ape.svg'
import chain from 'src/static/logos/ido/chain.svg'
import magic from 'src/static/logos/ido/magic.svg'
import poolz from 'src/static/logos/ido/poolz.svg'

import './IdoBanner.sass'

export default function IdoBanner() {
  const [open, setOpen] = useState(true)
  return (
    <div id="ido-banner" className={open ? '' : 'mini'}>
      <img src={token} alt="lay3r token" />
      <h1 onClick={() => setOpen(true)}>
        <span>
          <MarkdownMessage id="ido-banner.text" />
        </span>
      </h1>
      <div className="icon icon-close" onClick={() => setOpen(false)} />
      <div className="logo-grid">
        <a
          href="https://www.apeterminal.io/project/autolayer"
          rel="noopener noreferrer"
          className="logo-link"
          target="_blank"
        >
          <img src={ape} alt="Ape Terminal" />
          <span>Ape Terminal</span>
          <strong>LIVE NOW</strong>
        </a>
        <a
          href="https://pad.chaingpt.org/buy-token/151?utm_source=twitter&utm_medium=social&utm_campaign=autolayer_ido"
          rel="noopener noreferrer"
          className="logo-link"
          target="_blank"
        >
          <img src={chain} alt="ChainGPT" />
          <span>ChainGPT</span>
          <strong>TBA</strong>
        </a>
        <a
          href="https://launchpad.magicsquare.io/projects/autolayer"
          rel="noopener noreferrer"
          className="logo-link"
          target="_blank"
        >
          <img src={magic} alt="Magic Launchpad" />
          <span>Magic Launchpad</span>
          <strong>Sept. 23th</strong>
        </a>
        <a
          href="https://www.poolz.finance/project-details/about/219"
          rel="noopener noreferrer"
          className="logo-link"
          target="_blank"
        >
          <img src={poolz} alt="Poolz Finanze" />
          <span>Poolz Finanze</span>
          <strong>Sept. 23th</strong>
        </a>
      </div>
    </div>
  )
}
